.main-sidebar {
	border-right: 1px solid $color-secondary;
	border-top: none;
	width: $side-nav-width;
	padding: 20px;
	position: fixed;
	height: calc(100vh - 70px);
	top: 70px;
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	.profile-sec {
		border-radius: 10px !important;
		background-color: $color-white;
		padding: 15px 10px;
		.img-box {
			height: 48px;
			width: 48px;
			border: 2px solid $color-secondary;
			border-radius: 50%;
			overflow: hidden;
		}
		.side-bar-name {
			line-height: 14px;
			position: relative;
			top: 4px;
		}
		.side-bar-id {
			position: relative;
			top: 4px;
		}
	}
	.sidebar-menu {
		ul.main-menu-list {
			margin: 20px 0 30px 0;
			list-style: none;
			li.sidebar-items {
				padding: 10px 0;
				margin: 0;
				cursor: pointer;
				position: relative;
				a {
					text-decoration: none;
					color: $color-dark;
					&.active {
						position: relative;
						&::before {
							content: "";
							position: absolute;
							height: 40px;
							width: 3px;
							background-color: $color-primary;
							left: -20px;
							top: -8px;
							margin: 0;
						}
						.txt-box {
							div {
								color: $color-primary;
							}
						}
						.icon-box {
							.active-tab {
								display: block;
							}
							.inactive-tab {
								display: none;
							}
						}
						i {
							color: $color-primary;
						}
						.material-icons {
							color: $color-primary;
						}
					}
				}
				.icon-box {
					position: relative;
					img,
					.material-icons {
						position: absolute;
						top: 4px;
						bottom: 0;
						left: 0;
						right: auto;
						margin: auto 0;
						color: #9d9d9d;
					}
					.sp-badge {
						display: none;
					}
				}
				.txt-box {
					div {
						font-weight: 500;
					}
				}
				.active-tab {
					display: none;
				}

				.provider-thumbnails {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					left: auto;
					margin: auto 0;
					height: 36px;
					.img-box {
						height: 34px;
						width: 34px;
						border-radius: 50%;
						border: 2px solid #5cb85c;
						overflow: hidden;
						display: inline-block;
						background-color: rgba(157, 157, 157, 0.3);
						margin-left: -7px;
						&.sub-dr {
							border-color: $color-muted;
						}
					}
				}
				.disable-link {
					pointer-events: none;
				}
				.sub-menu-list {
					list-style: none;
					padding: 0 0 0 30px;
					li {
						margin: 5px 0;
						a {
							color: rgba(0, 0, 0, 0.5);
							padding: 4px 0;
							margin: 3px 0;
							position: relative;
							&.active {
								color: $color-primary;

								&::before {
									display: none;
								}
							}
						}
						&:last-child {
							margin-bottom: 0;
						}
						&:first-child {
							margin-top: 7px;
						}
					}
				}
				.count-val-box {
					background-color: $color-primary;
					color: $color-white;
					font-weight: 600;
					padding: 4px 10px 2px 10px;
					border-radius: 30px;
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					left: auto;
					margin: auto 0;
				}

				&.hasSubmenu {
					position: relative;
					// &::after
					// {
					// 	content: '';
					// 	position: absolute;
					// 	top: 11px;
					// 	right: 0;
					// 	border-width: 0 2px 2px 0;
					// 	display: inline-block;
					// 	border-color: $color-primary;
					// 	border-style: solid;
					// 	transform: rotate(-45deg);
					// 	-webkit-transform: rotate(-45deg);
					// 	height: 8px;
					// 	width: 8px;
					// 	transition: all .2s ease-out;
					// }
					a {
						&.navaccordion {
							&::after {
								flex-shrink: 0;
								width: 0.875rem;
								height: 0.875rem;
								margin-left: auto;
								content: "";
								background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
								background-repeat: no-repeat;
								background-size: 0.875rem;
								transition: transform 0.2s ease-in-out;
								position: absolute;
								top: 14px;
								right: 0;
							}
							&::after {
								transform: rotate(-90deg);
								-webkit-transform: rotate(-90deg);
								-moz-transform: rotate(-90deg);
								-ms-transform: rotate(-90deg);
								-o-transform: rotate(-90deg);
							}
						}
					}

					.navaccordion:not(.collapsed)::after {
						transform: rotate(0deg);
						-webkit-transform: rotate(0deg);
						-moz-transform: rotate(0deg);
						-ms-transform: rotate(0deg);
						-o-transform: rotate(0deg);
					}
				}
			}
			li.sidebar-header {
				cursor: default;
			}
		}
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.main-sidebar {
		width: 240px;
		padding: 20px 15px;
		.profile-sec {
			.avatar-lg {
				margin-right: 12px !important;
				align-self: center;
				width: 40px !important;
				height: 40px !important;
			}
			.txt-box {
				font-size: 13px;
				.charlmt {
					width: 138px !important;
				}
			}
		}

		.sidebar-menu ul.main-menu-list li.sidebar-items a {
			font-size: 13px;
		}
	}
	.main-middle-sec {
		padding-left: 240px;
		.nav-tabs .nav-item a {
			font-size: 13px;
			margin: 0 18px 0 0;
		}
		.stp-action {
			position: relative;
			.guideline {
				position: absolute;
				top: -5px;
				left: 0;
			}
			.btn {
				font-size: 13px;
				margin-top: 15px;
			}
		}
	}
	.main-header {
		.btn-warning {
			font-size: 13px;
		}
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.main-sidebar {
		display: none;
	}
	.main-middle-sec {
		padding-left: 0;
	}
}
