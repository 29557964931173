@tailwind base;
@tailwind components;
@tailwind utilities;

/* One line table headings */
.nowrap th, .nowrap {
    white-space: nowrap;
}

/* Scroll 2 */
.sc2::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.sc2::-webkit-scrollbar-track {
    background-color: rgb(210 210 210 / 18%);
    border-radius: 10px;
}

.sc2::-webkit-scrollbar-thumb {
    background-color: #dedede;
    border-radius: 10px;
}

/* Table thead bg */
table.th-bg thead tr.bg-alicblue:nth-child(2) th {
    background-color: aliceblue;
}