.schedule-sec,
.advances-table {
	.table > :not(:last-child) > :last-child > * {
		border-bottom: none;
	}
	table {
		border-collapse: separate;
		border-spacing: 0 10px;
		width: 100%;
	}
	th {
		border: none;
		padding: 10px 15px !important;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 500;
	}
	.provider-img-box {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		border: 1px solid #d8d8d8;
		overflow: hidden;
	}
	.btn-link {
		font-size: 14px;
		font-weight: normal !important;
	}

	tr:nth-child(2n) td {
		background-color: #f8f9fa !important;
	}

	tr {
		td {
			border-bottom: 1px solid #d8d8d8;
			border-top: 1px solid #d8d8d8;
			padding: 15px 15px;
			vertical-align: middle;
			.bg-danger {
				background-color: #d9534f !important;
			}
			.bg-primary {
				background-color: #5bc0de !important;
			}
			.bg-success {
				background-color: #5cb85c !important;
			}
			.badge {
				padding: 5px 10px;
			}
		}
		td:first-child {
			border-left: 1px solid #d8d8d8;
			border-radius: 10px 0 0 10px;
			padding-right: 0;
		}

		td:last-child {
			border-right: 1px solid #d8d8d8;
			border-radius: 0 10px 10px 0;
		}
		&.provider-verified {
			td {
				border-color: #f0ad4e !important;
				background-color: rgba(240, 173, 78, 0.1);
			}
		}
	}
}
.schedule-sec {
	th {
		&:first-child {
			padding-left: 0 !important;
		}
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1320px) {
	.schedule-sec tr td {
		padding: 15px 15px;
	}
	.schedule-sec th {
		padding: 10px 15px !important;
	}
	.schedule-sec .waitlist-table th {
		min-width: 120px;
	}
	.middle-navbar .nav-tabs .nav-item a {
		margin: 0 12px;
		font-size: 14px;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.schedule-sec table {
		th {
			padding: 10px !important;
			.form-select {
				width: auto !important;
			}
		}
		td {
			padding: 10px;
			font-size: 13px;
			.avatar-lg {
				margin-right: 10px !important;
				display: none;
			}
		}
	}
	.advances-table table {
		td {
			max-width: 245px;
			padding: 10px;
			font-size: 13px;
			.avatar-lg {
				margin-right: 10px !important;
			}
		}
	}
}
